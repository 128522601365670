import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="App-title">
          <img src="icon.png" className="App-icon"/>Cadence App
        </h1>

        <h2>All your workouts,<br/>on one map</h2>
        <a className="App-badge" href="https://apps.apple.com/us/app/cadence-map-your-workouts/id1479336782"><img src="App_Store_Badge.svg"/></a>
      </header>
      <main>
        <div>
          <img src="screen_shot.jpg" className="App-screen_shot"/>
          <img src="screen_shot1.jpg" className="App-screen_shot"/>
          <img src="siri.png" className="App-screen_shot"/>
        </div>
        <p className="App-text">
          Cadence allows you to view all your workouts, all at once, on one map.
          Perfect for running, swimming, biking, hiking and all other forms of outdoor workout.
          View splits, compare times and find new routes with ease.
          <br/><br/>
          Cadence is buit with HealthKit support and fetches all your old workouts automatically,
          no setup required.
        </p>
        <h3>Privacy</h3>
        <p className="App-text">
          Cadence does all computation on-device and does not share your data with anyone.
          Your workout and personal data is stored on your own device and never leaves it.
        </p>
        <h3>Credit</h3>
        <p className="App-text">
          Thanks to <a href="https://undraw.co/">unDraw.co</a> for amazing drawings
        </p>
        <h3>Support</h3>
        <p className="App-text">
          Email <a href="mailto:linus@ekensteenlofgren.se">linus@ekensteenlofgren.se</a> if you have any problems
        </p>
      </main>
      <footer className="App-footer">
        <p>Made by <a href="https://linuslofgren.se/">Linus von Ekensteen Löfgren</a></p>
      </footer>
    </div>
  );
}

export default App;
